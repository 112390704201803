import { HTMLAttributes, ReactNode, useEffect } from "react";
import {
  SiteFooter,
  SiteFooterProps,
} from "design-system/components/universal/site-footer/site-footer";
import {
  ExtractLazyComponent,
  LazyComponent,
} from "design-system/components/system/lazy-component/lazy-component";

export type SiteHeaderComponent = ExtractLazyComponent<
  "SiteHeader" | "OnlineSiteHeader"
>;

import { dataLayer, GTMPageMetadata } from "design-system/utils/data-layer";
import { Theme } from "design-system/utils/theme";

export interface BaseLayout extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  footer?: SiteFooterProps;
  header?: SiteHeaderComponent;
  theme?: Theme;
  gtmPageEvent?: GTMPageMetadata;
}

export function BaseLayout({
  children,
  footer,
  header,
  gtmPageEvent,
  ...props
}: BaseLayout) {
  useEffect(() => {
    if (gtmPageEvent) {
      dataLayer?.push(gtmPageEvent);
    }
  }, [gtmPageEvent]);

  return (
    <div {...props}>
      <div>
        {header && <LazyComponent {...header} />}
        {children}
      </div>

      {footer && <SiteFooter {...footer} />}
    </div>
  );
}
