import {
  LinkEntry,
  LinkListEntry,
} from "frontend/contentful/schema/primitives";
import { isContentType } from "frontend/contentful/lib/is-content-type";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { RenderContentfulRichText } from "../utils/render-contentful-rich-text";
import { useContentful } from "frontend/hooks/use-contentful";
import { GlobalMenuSubmenu } from "design-system/components/universal/global-menu/global-menu-sub";
import { GlobalMenuNavItem } from "design-system/components/universal/global-menu/global-menu-item";

export const createGlobalMenuNavItemProps = createContentfulProps<
  LinkEntry | LinkListEntry,
  GlobalMenuNavItem
>(({ entry, createEditAttributes }) => {
  const { getEntry, getEntryUrl } = useContentful();

  if (isContentType<LinkEntry>("link", entry)) {
    if (entry.fields?.text) {
      return {
        id: entry.sys.id,
        label: entry.fields.text,
        link: getEntryUrl(entry),

        editAttributes: {
          title: createEditAttributes({ entry, fieldId: "title" }),
        },
      };
    }
  }

  if (isContentType<LinkListEntry>("linkList", entry)) {
    if (entry.fields?.title) {
      const link = getEntry(entry.fields.link);
      const submenu: GlobalMenuSubmenu = {
        items: [],
        header: {
          label: link?.fields.text || entry.fields.title,
          link: getEntryUrl(entry.fields.link),
          subtitle: (
            <RenderContentfulRichText document={entry.fields.description} />
          ),

          editAttributes: {
            title: createEditAttributes({ entry, fieldId: "title" }),
            subtitle: createEditAttributes({ entry, fieldId: "description" }),
          },
        },
      };

      entry.fields?.links?.forEach((entry) => {
        const item = createGlobalMenuNavItemProps(entry);
        if (item) submenu.items.push(item);
      });

      return {
        id: entry.sys.id,
        label: entry.fields.title,
        submenu,
      };
    }
  }
});
