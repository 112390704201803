import {
  LinkEntry,
  LinkListEntry,
} from "frontend/contentful/schema/primitives";
import { isContentType } from "frontend/contentful/lib/is-content-type";
import { LocalNavigationItemProps } from "design-system/components/universal/local-navigation/local-navigation-item";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";

import { useContentful } from "frontend/hooks/use-contentful";

type NavigationLinkEntry = LinkEntry | LinkListEntry;

export const createNavigationItemProps = createContentfulProps<
  NavigationLinkEntry,
  LocalNavigationItemProps
>(({ entry, createEditAttributes }) => {
  const { getEntryUrl, getEntries } = useContentful();

  const transform = (entry: NavigationLinkEntry) => {
    const props: LocalNavigationItemProps = {
      title: "",
      editAttributes: {
        title: createEditAttributes({ entry, fieldId: "title" }),
      },
    };

    if (isContentType<LinkListEntry>("linkList", entry)) {
      props.url = getEntryUrl(entry.fields.link);
      if (entry.fields?.title) props.title = entry.fields.title;

      if (entry.fields?.links) {
        props.submenu = getEntries(entry.fields.links)?.map((link) =>
          transform(link),
        );
      }
    } else if (isContentType<LinkEntry>("link", entry)) {
      props.url = getEntryUrl(entry);
      if (entry.fields?.text) props.title = entry.fields.text;
    }

    return props;
  };

  return transform(entry);
});
