import { SiteEntry } from "frontend/contentful/schema/universal";
import { PageProps, SearchResults } from "frontend/types";

export const hasSearchData = (data: SearchResults) =>
  typeof data === "object" && data?.hits;

export const setdropDownActive = (pageProps: PageProps) => {
  if (pageProps.request.domain === "library") return true;
  return false;
};

export const setdropDownUrl = (site?: SiteEntry) => {
  if (site?.fields.title === "Baker Library") {
    return "/api/fetch/baker-search";
  }
  return "/api/fetch/site-search";
};
