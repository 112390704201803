import {
  Theme,
  getSplitTopperTheme,
  getLargeAssetTopperTheme,
  SiteHeaderTheme,
  defaultPageSectionTheme,
} from "design-system/utils/theme";
import { isContentType } from "frontend/contentful/lib/is-content-type";
import * as Toppers from "frontend/contentful/schema/toppers";
import {
  LandingPageTopperEntry,
  DetailPageTopperEntry,
  ArchivePageTopperEntry,
  ArticlePageTopperEntry,
} from "frontend/contentful/schema/pages";

type TopperEntry =
  | LandingPageTopperEntry
  | DetailPageTopperEntry
  | ArchivePageTopperEntry
  | ArticlePageTopperEntry;

const defaultSiteHeaderTheme = defaultPageSectionTheme;

export function getSiteHeaderTheme(
  topper: TopperEntry | undefined,
): SiteHeaderTheme {
  // infer theme from topper theme if exists, otherwise use default theme
  if (isContentType<Toppers.CardTopperEntry>("cardTopper", topper)) {
    return topper?.fields?.theme || defaultSiteHeaderTheme;
  }

  // default to dark theme for participant story topper
  if (
    isContentType<Toppers.ParticipantStoryTopperEntry>(
      "participantStoryTopper",
      topper,
    )
  ) {
    return "dark";
  }

  // infer theme from topper spot theme if exists
  if (
    isContentType<Toppers.SplitArticleTopperEntry>("splitArticleTopper", topper)
  ) {
    return getSplitTopperTheme(topper?.fields?.spotTheme);
  }

  if (
    isContentType<Toppers.LargeAssetTopperEntry>("largeAssetTopper", topper)
  ) {
    return getLargeAssetTopperTheme(topper?.fields?.spotTheme);
  }

  // grab theme from topper if exists
  if (topper?.fields?.theme) {
    return topper?.fields.theme as Theme;
  }

  return defaultSiteHeaderTheme;
}
